import axios, { AxiosRequestConfig } from 'axios';
import { GET_PARAMETER, GET_PROJECT } from 'const';
import { Menu, Project } from 'types';

export const defaultData = {
  name: '-',
  address: '-',
  status: 'true',
  city: '-',
  state: '-',
  constCenter: '',
};

export const fetchProject = (
  { id }: any,
  config?: AxiosRequestConfig<Menu[]>,
) => {
  return axios.get<Project>(GET_PROJECT.replace(':id', id), config);
};

export const fetchParameterCostCenter = async () => {
  return await axios.get(GET_PARAMETER.replace(':key', 'COST_CENTER_PROJECT'));
};

export const fetchParameterConstructionSite = async () => {
  return await axios.get(
    GET_PARAMETER.replace(':key', 'CONSTRUCTION_SITE_PROJECT'),
  );
};
