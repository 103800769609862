import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Input,
  SnackBar,
  Typography
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { useDialog, useGetPermissions, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoAdd } from 'react-icons/io5';
import { PaginationType, Project } from 'types';
import { createFilterStructure } from 'utils';
import { fetchParameterConstructionSite, fetchParameterCostCenter } from '../ProjectTemplate.const';
import {
  defaultListProjectTemplateFilter,
  defaultPagination,
  defaultSortModel,
  fetchProcess,
  getColumns,
  updateStatus
} from './ListProjectTemplate.const';
import ListProjecteApiLoading from './ListProjectTemplate.loading';
import {
  ListProjectTemplateFilterSchema,
  ListProjectTemplateFilterType
} from './ListProjectTemplate.schema';
import { ListProjectTemplateProps } from './ListProjectTemplate.types';

const ListProjectTemplate = ({ ...props }: ListProjectTemplateProps) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [costCenter, setcostCenter] = useState<0 | 1>(0);
  const [constructionSite, setConstructionSite] = useState<0 | 1>(0);

  useEffect(() => {
    fetchParameterCostCenter().then((response) => {
      setcostCenter(response.data)
    })

    fetchParameterConstructionSite().then((response) => {
      setConstructionSite(response.data)
    })
  }, []);

  const { canEdit, canInsert } = useGetPermissions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ListProjectTemplateFilterType>({
    defaultValues: defaultListProjectTemplateFilter,
    resolver: yupResolver(ListProjectTemplateFilterSchema),
  });

  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [action, setAction] = useState<'remove' | 'edit' | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();
  const [actionSnackBar, setActionSnackBar] = useState<'remove' | 'edit' | undefined>(
    undefined,
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const confirmEdit = useCallback(
    (id: number) => {
      onOpen(id);
      setAction('edit');
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setActionSnackBar(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const columns = useMemo(
    () => getColumns(confirmEdit, canEdit, costCenter, constructionSite),
    [confirmEdit, canEdit],
  );

  const [filterModel, setFilterModel] = useState<filterModelType<Project>[]>([]);

  const {
    loading,
    error,
    handleData,
    getData,
    data: process,
  } = useGetTable<PaginationType<Project>, tableModelType<Project>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });

  const tableControllers = useTable<Project>(
    {
      filterModel,
      sortModel: defaultSortModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Project>, 'paginationModel'>,
    ) => Promise<PaginationType<Project>>,
  );

  const editProject = async (idProject?: number) => {
    setIsUpdating(true);
    if (idProject) {
      try {
        await updateStatus(idProject);
        setOpen("success");
        //Sorting to refresh
        tableControllers.onSortModel(defaultSortModel);
      }
      catch (error) {
        setOpen("error");
      } finally {
        setActionSnackBar("edit");
        setIsUpdating(false);
        onClose();
      }
    }
  };

  const onSubmit = useCallback(
    (filterListProjectTemplate: ListProjectTemplateFilterType) => {
      const filterProjectName = createFilterStructure(
        'name',
        'contains',
        filterListProjectTemplate.name,
      );

      tableControllers.paginationModel.page = 0;
      const modelFilter = [filterProjectName].filter((item) => item);
      setFilterModel(modelFilter as filterModelType<Project>[]);
    },
    [tableControllers],
  );

  const selectedProject = useMemo(
    () =>
      selected !== undefined
        ? process?.result.find((project) => project.id === selected)
        : undefined,
    [selected, process],
  );

  return (
    <>
      <Table<Project>
        {...tableControllers}
        numberOfVisibleColumns={4}
/*         title={`Encontramos ${tableControllers.paginationModel.rowsCount} proyectos`}
 */        columns={columns}
        rows={process?.result || []}
        error={!!error}
        loading={loading}
        exportOptions={{
          filename: 'Proyectos',
        }}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            {/* canInsert() */ true && <Grid item>
              <IconButton
                variant="contained"
                href="/pages/servicios-publicos/management/project/create"
                info="Crear proyecto"
              >
                <IoAdd />
              </IconButton>
            </Grid>}
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={4}
                  wrap="nowrap"
                >
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      placeholder="Busca por nombre"
                      {...register('name')}
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      isSmall
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Filtrar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title="Confirma esta acción"
      >
        {!isUpdating ?
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={12}>
              {action === 'remove' && (
                <Typography variant="h2">
                  {`¿Estas seguro de eliminar el proyecto ${selectedProject?.name}?`}
                </Typography>
              )}
              {action === 'edit' && (
                <Typography variant="h2">
                  {`¿Estas seguro de editar el estado del proyecto ${selectedProject?.name}?`}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Button variant="contained" color="grey" onClick={onClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              {action === 'edit' && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    editProject(selectedProject?.id)
                  }
                  loading={loadingForm}
                >
                  Si, deseo modificarlo
                </Button>
              )}
            </Grid>
          </Grid> : <ListProjecteApiLoading />}
      </Dialog>
      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' &&
          actionSnackBar === 'edit' &&
          'Se editó el estado del proyecto exitosamente'}
        {open === 'success' &&
          actionSnackBar === 'remove' &&
          'Eliminamos el proyecto exitosamente'}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default ListProjectTemplate;
