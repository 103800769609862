export const CUSTOMER =
  process.env.REACT_APP_API + '/v1/customer?domainOrSuffix=:customer';
/**DEV-AUTH*/
export const DEV = process.env.REACT_APP_DEV;
export const LOGIN = '/v1/auth/login';
export const LOGIN_GOOGLE = '/v1/auth/google';
export const LOGIN_AZURE = '/v1/auth/azure';
export const LOGOUT = '/v1/auth/logout';
export const REQUEST_PERMISSIONS = '/v1/auth/request-permissions';
export const CHANGE_PASSWORD = '/v1/auth/change-password';

export const GET_ALL_CUSTOMERS = '/v1/customer';

export const GET_PROCESS = '/v1/processes/:id';
export const GET_ALL_PROCESS = '/v1/processes';
export const GET_ALL_PROCESS_PAGINATION = '/v1/processes/pagination';
export const GET_ALL_AM = '/v1/automationmanager';
export const GET_AM = '/v1/automationmanager/:id';
export const CREATE_AM = '/v1/automationmanager';
export const UPDATE_AM = '/v1/automationmanager/:id';
export const DELETE_AM = 'v1/automationmanager/:id';
export const DOWNLOAD_LOGS = 'v1/automationmanager/download-logs';
export const LIST_LOGS = 'v1/automationmanager/list-logs';

export const GET_EXCEPTIONS = 'v1/exception';
export const GET_EXCEPTION = 'v1/exception/:id';
export const GET_EXCEPTION_EXTRADATA_FILE = 'v1/exception/extradata/:id';
export const UPDATE_EXCEPTIONS = 'v1/exception';

export const SEARCH_TRANSACTIONS = '/v1/transactions';
export const GET_STATUS_BY_PROCESS = '/v1/transactions/:id/state';
export const RE_PROCESS_TRANSACTION = '/v1/transactions/:id/reprocess/:user';
export const SEARCH_TRANSACTIONS_PER_CHECK = '/v1/transactions/verification';

export const GET_DOCUMENTS_BY_TRANSACTIONS_PER_VERIFICATION =
  '/v1/documents/verification-transaction';
export const FIX_DOCUMENT_VERIFICATION = '/v1/documents/fix/:id';
export const REJECT_DOCUMENT_VERIFICATION = '/v1/documents/reject/:id';

export const CREATE_USER = 'v1/user';
export const GET_USERS = 'v1/user';
export const DELETE_USER = 'v1/user/:id';
export const UPDATE_USER_STATE = 'v1/user/toggle-state/:id';
export const UPDATE_USER = 'v1/user/:id';
export const GET_USER = 'v1/user/:id';

export const GET_TASK = 'v1/tasks';

export const GET_ROLES = 'v1/role';
export const CREATE_ROLE = 'v1/role';
export const DELETE_ROLE = 'v1/role/:id';
export const UPDATE_ROLE_STATE = 'v1/role/toggle-state/:id';
export const UPDATE_ROLE = 'v1/role/:id';
export const GET_ROLE = 'v1/role/:id';

export const GET_ROLE_MENU = 'v1/menu';

export const UPLOAD_COMPANY_SERVICE_GROUPING =
  '/v1/company-service-grouping/upload-services';
export const GET_COMPANY_SERVICE_GOUPING_EXCEL =
  '/v1/company-service-grouping/download-Company-Service-Grouping-Excel';
export const GET_SERVICE_TYPES = 'v1/type-service';

export const GET_PROJECTS = 'v1/project';
export const GET_PROJECT = 'v1/project/:id';
export const CREATE_PROJECT = 'v1/project';
export const UPDATE_PROJECT = '/v1/project/:id';
export const UPDATE_PROJECT_STATUS = '/v1/project/:id';

export const GET_COMPANIES = 'v1/company-service';
export const GET_COMPANY = 'v1/company-service/:id';
export const CREATE_COMPANY = 'v1/company-service';
export const UPDATE_COMPANY = '/v1/company-service/:id';
export const UPDATE_COMPANY_STATUS = '/v1/company-service/:id';

export const GET_HISTORY_RECORDS = 'v1/history';
export const GET_HISTORY_PENDING_RECORDS = 'v1/history/pending';
export const UPDATE_HISTORY = '/v1/history/update-pay-date';
export const DOWNLOAD_INVOICE = '/v1/history/download-invoice/:id';
export const DOWNLOAD_INVOICES_ZIP = '/v1/history/download-invoices-zip';
export const DOWNLOAD_INVOICES_COMBINED =
  '/v1/history/download-invoices-combined';
export const DOWNLOAD_INVOICES_COMBINED_PENDING =
  '/v1/history/download-invoices-pending-combined';
export const DOWNLOAD_INVOICES_ZIP_PENDING =
  '/v1/history/download-invoices-pending-zip';

export const GET_GROUPINGS = 'v1/grouping';
export const CREATE_GROUPING = 'v1/grouping';
export const UPDATE_GROUPING = 'v1/grouping/:id';
export const UPDATE_GROUPINGS = 'v1/grouping';
export const GET_GROUPING = 'v1/grouping/:id';
export const UPLOAD_GROUPING = '/v1/grouping/upload-groupings';
export const GET_GOUPING_EXCEL = '/v1/grouping/download-groupings-excel';

export const GET_DASHBOARD_DATA_HISTORY = 'v1/dashboard/history-report';
export const GET_DASHBOARD_DATA_HISTORY_DUE_HISTORY =
  'v1/dashboard/due-history';

export const GET_PARAMETER = '/v1/parameter/:key';
