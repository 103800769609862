import axios from 'axios';
import { GET_PARAMETER } from 'const';

export const fetchParameterCostCenter = async () => {
  return await axios.get(GET_PARAMETER.replace(':key', 'COST_CENTER_PROJECT'));
};

export const fetchParameterConstructionSite = async () => {
  return await axios.get(
    GET_PARAMETER.replace(':key', 'CONSTRUCTION_SITE_PROJECT'),
  );
};
