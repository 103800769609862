import { object, string, TypeOf } from 'yup';

export const EditProjectSchema = object({
  name: string().required(),
  address: string().required(),
  status: string().required(),
  city: string().required(),
  state: string().required(),
  numberConstCenter: string().matches(/^\d+$/, 'Solo se permite números'),
  descriptionConstCenter: string(),
  numberConstructionSite: string().matches(/^\d+$/, 'Solo se permite números'),
  descriptionConstructionSite: string(),
});

export type EditProjectType = TypeOf<typeof EditProjectSchema>;
